<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      ref="formItem"
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]"
    >
      <span slot="label" :class="labelCssClasses + ' xref_multi_field_label'" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>
    <div @contextmenu.prevent="showContextMenu($event)">
      <treeselect
        ref="treeselect"
        v-model="localValue"
        :placeholder="placeholder || $locale.main.placeholder.select"
        class="custom_scrollbar xref_multi_field"
        :options="computedOptions"
        :clearable="clearable"
        :load-options="loadOptions"
        :disabled="_isReadonly"
        :normalizer="normalizer"
        :clear-on-select="true"
        :clear-value-text="treeXrefField.clearValueText"
        :no-children-text="treeXrefField.noChildrenText"
        :loading-text="$locale.main.message.loading"
        :no-options-text="treeXrefField.noOptionsText"
        :no-results-text="treeXrefField.noResultsText"
        :match-keys="treeXrefField.matchKeys"
        :disable-branch-nodes="!disableBranchNodes"
        :async="true"
        :disable-immediate-search="true"
        :cache-options="false"
        :append-to-body="true"
        :searchable="true"
        :multiple="true"
        :limit="limit"
        :limit-text="treeXrefField.limitText"
        :value-consists-of="treeXrefField.valueConsistsOf"
        @open="onMenuOpen"
        @select="onSelect"
        @search-change="onSearch"
      >
        <template slot="before-list">
          <div v-show="showSearchTip" class="vue-treeselect__tip vue-treeselect__seacrh-promt-tip">
            <div class="vue-treeselect__icon-container">
              <span class="vue-treeselect__icon-warning"/>
            </div>
            <span class="vue-treeselect__tip-text vue-treeselect__seacrh-promt-tip-text">
              {{ treeXrefField.pressEnterToSearchText }}
            </span>
          </div>
        </template>
        <template
          slot="option-label"
          slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
        >
          <label :title="node.label" :class="labelClassName" v-if="!node.raw.isLoadingLabel" style="color: #606266;">
            {{ node.label }}
            <span v-if="node.raw.children_count" :class="countClassName">
              ({{ node.raw.children_count }})
            </span>
          </label>
          <div v-else class="vue-treeselect__loading-tip">
            <div class="vue-treeselect__icon-container">
              <span class="vue-treeselect__icon-loader"></span>
            </div>
            <span class="vue-treeselect__tip-text vue-treeselect__loading-tip-text">
              {{ $locale.main.message.loading }}
            </span>
          </div>
        </template>
      </treeselect>
    </div>
    <vue-context ref="context-menu" class="el-dropdown-menu  el-dropdown-menu--small tab-menu" v-slot="{ data }">
      <div class="el-dropdown-menu__item" @click="copyLink(data)">
        <i class="el-icon-document-copy"></i>Копировать
      </div>
    </vue-context>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import InputLabel from '@/mixins/inputLabel.js'

// components
import Treeselect, { LOAD_CHILDREN_OPTIONS, ASYNC_SEARCH } from '@bingosoftnn/vue-treeselect'
import '@bingosoftnn/vue-treeselect/dist/vue-treeselect.css'
import { VueContext } from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'

// utils
import FilterBuilder, { EComponentTypes } from '../utils'
import { deepCompare } from '@/helpers'

export default {
  name: 'xref_multi_field',
  mixins: [mixin, registryMixin, InputLabel],
  components: {
    Treeselect,
    VueContext
  },
  props: {
    label: {
      description: 'name',
      type: String
    },
    placeholder: {
      description: 'placeholder',
      type: String
    },
    tooltip: {
      description: 'tooltip',
      type: String
    },
    alias: {
      description: 'xref_alias',
      type: String
    },
    addressFieldAliasGarMulti: {
      description: 'xref_multi_addres_field_gar',
      type: String,
      options: {
        removeSpaces: true,
        tooltip: {
          show: true,
          content: 'xref_multi_addres_field_gar_des'
        }
      }
    },
    stateId: {
      description: 'xref_state',
      type: String
    },
    parameters: {
      type: Array,
      default: () => {
        return []
      },
      editor: 'Filters',
      options: {
        title: 'Параметры',
        showXrefOption: false,
        showEqualsTypes: true
      }
    },
    limit: {
      description: 'value_counter',
      type: Number,
      default: () => 3
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true,
        showEqualsTypes: true
      }
    },
    groupBy: {
      description: 'default_group_attr',
      type: String,
      options: {
        removeSpaces: true,
        tooltip: {
          show: true,
          content: 'work_with_xref'
        }
      }
    },
    groupOrderBy: {
      description: 'xref_sort',
      type: String,
      options: {
        removeSpaces: true
      }
    },
    recursiveGroup: {
      description: 'xref_recursive',
      type: Boolean
    },
    disableBranchNodes: {
      description: 'group_selection',
      default: false,
      type: Boolean,
      options: {
        tooltip: {
          show: true,
          content: 'when_group'
        }
      }
    },
    clearable: {
      type: Boolean,
      description: 'clearable',
      default: true
    },
    saveCardNow: {
      type: Boolean,
      frozen: true
    },
    applyRestrictions: {
      type: Boolean,
      description: 'apply_restrictions',
      default: false
    }
  },
  data () {
    return {
      treeXrefField: {
        matchKeys: ['name', 'id'],
        valueConsistsOf: 'LEAF_PRIORITY',
        clearValueText: 'Очистить',
        noChildrenText: 'Нет данных',
        noOptionsText: 'Нет данных',
        noResultsText: 'Не найдено',
        limitText: count => `+ ${count}`,
        pressEnterToSearchText: 'Для поиска нажмите Enter'
      },
      isAllRecordLoaded: false,
      search: null,
      offset: 0,
      localValue: [],
      foundOptions: [],
      options: [],
      loaded: false,
      loading: false,
      searchLoading: false,
      searchLoaded: false,
      saveOptions: false
    }
  },
  async mounted () {
    if (this.isEditor()) {
      return false
    }
    this.parseValue()
    if (this.validationNeeded) {
      if (this.value === '[]' || !this.value) {
        this.$emit('input', this.localValue)
        this.$refs.formItem.form.validateField(this.name)
      }
      this.$refs.formItem.form.validateField(this.name)
    }
    let elemtInput = this.$refs?.treeselect.$el.querySelector('.vue-treeselect__input')
    if (elemtInput) {
      elemtInput.setAttribute('enterkeyhint', 'enter')
    }
  },
  watch: {
    value: {
      async handler (value) {
        const isSameArrays = deepCompare(value, this.localValue)
        // console.log('value', { value }, this.localValue)
        // console.log({ isSameArrays })
        if (!isSameArrays) {
          await this.parseValue()
          this.$emit('input', this.localValue || [])
          this.$set(this.getRawData(), this.name, this.getRawModel)
        }
      }
    },
    localValue: {
      async handler (value) {
        const isSameArrays = deepCompare(value, this.value)
        // console.log('localValue', { value }, this.value)
        // console.log({ isSameArrays })
        // this.options = await this.loadData(true)
        if (!isSameArrays) {
          this.$emit('input', this.localValue || [])
        }
        this.$set(this.getRawData(), this.name, this.getRawModel)
      }
    },
    dataFilters: {
      async handler () {
        if (this.loaded) {
          this.offset = 0
          this.options = await this.loadData(true)
        }
      }
    },
    stateParams: {
      async handler () {
        if (this.loaded) {
          this.offset = 0
          if (!this.loading) {
            this.loading = true
            setTimeout(async () => {
              this.options = await this.loadData(true)
            }, 100)
          }
        }
      }
    },
    options () {
      this.parseValue()
    }
  },
  computed: {
    getRawModel () {
      let data = []
      this.localValue.forEach((xrefItem) => {
        if (this.options.find(item => item.id === xrefItem)) {
          data.push({ 'id': xrefItem, 'name': this.options.find(item => item.id === xrefItem).name })
        }
      })

      return data
    },
    showSearchTip () {
      return this.search && !this.searchLoading && !this.searchLoaded
    },
    attributeId () {
      return parseInt(/attr_([0-9]+)_/i.exec(this.name) ? /attr_([0-9]+)_/i.exec(this.name)[1] : 0)
    },
    computedOptions () {
      if (this.loading) {
        return [...this.options, ...[{ id: -1, isDisabled: true, isLoadingLabel: true }]]
      } else if (this.searchLoaded) {
        return this.foundOptions
      } else {
        return this.options
      }
    },
    dataFilters () {
      // let filters = []
      // if (this.filters) {
      //   this.filters.forEach((item) => {
      //     let type = `eq`
      //     if (item.isXref) {
      //       type = `eqx`
      //     }
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias) {
      //         filters.push(`${item.alias},${type},${this.getModel()[item.attribute] + '' || 0}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${type},${item.attribute}`)
      //     } else if (item.type === 'current_user') {
      //       filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
      //     }
      //   })
      // }
      // return filters
      if (!this.filters) return []
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.xrefMultiField
      )

      return builder.buildAsRegistryService()
    },
    stateParams () {
      let params = []
      this.parameters.forEach((item) => {
        if (!item.type || item.type === 'field') {
          if (this.getModel()[item.attribute] && item.alias) {
            params.push({ name: item.alias, value: this.getModel()[item.attribute] })
          }
        } else if (item.type === 'constant' && item.alias) {
          params.push({ name: item.alias, value: item.attribute })
        } else if (item.type === 'current_user') {
          params.push({ name: item.alias, value: this.$store.getters['Authorization/userId'] })
        }
      })
      return params
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  methods: {
    async getAddressGARById (addressId) {
      try {
        return (await this.$http.get(`${this.$config.api}/garaddressservice/addresses?id=${addressId}`)).data[0].address
      } catch (error) {
        console.error(`Ошибка получения адреса ГАР addressId: ${addressId}`)
        return null
      }
    },
    copyLink () {
      const id = this.localValue || []
      if (!id.length) return
      const label = id.map(id => this.$refs.treeselect.getNode(id).label).toString()
      navigator.clipboard.writeText(label)
    },
    showContextMenu (event) {
      this.$refs['context-menu'].open(event)
    },
    async parseValue () {
      if (!this.value) {
        this.localValue = []
        return false
      }
      let parsed = this.value
      if (typeof parsed === 'number' || parseInt(parsed) == parsed) {
        parsed = [parseInt(parsed)]
      } else if (typeof parsed === 'string') {
        try {
          parsed = JSON.parse(this.value)
        } catch (e) {
          try {
            parsed = JSON.parse(`[${this.value}]`)
          } catch (e) {
          }
        }
      }

      if (parsed instanceof Array && parsed.length > 0) {
        if (!parsed.every((e) => this.options.includes(e) || this.options.find((item) => item.id === e))) {
          // Если заполенено свойство addressFieldAliasGarMulti (ГАР)
          if (this.addressFieldAliasGarMulti) {
            this.getAddressGAR({ parsed, addresAttr: this.addressFieldAliasGarMulti })
          }
          let toLoading = false
          let existingKeys = this.options.map(opt => {
            return opt.id
          })
          parsed.forEach((item) => {
            if (item.id && item.name && !existingKeys.includes(item.id)) {
              this.options = this.options.concat(item)
            } else if (typeof item === 'number') {
              toLoading = true
            }
          })
          this.localValue = parsed.map((item) => item.id || item)
          if (toLoading) {
            this.options = await this.loadData(true, null, this.localValue.join(','))
            this.loaded = false
          }
        } else {
          this.localValue = parsed.map((item) => item.id || item)
        }
      } else {
        this.localValue = []
      }
    },
    normalizer (node) {
      return {
        id: node.id || '',
        label: this.getNodeLabel(node)
      }
    },
    async getAddressGAR ({ parsed, addresAttr }) {
      let addresIds = []
      if (Array.isArray(parsed)) {
        parsed.forEach(item => {
          let id = Array.isArray(item[addresAttr]) ? item[addresAttr][0] : item[addresAttr]
          if (id) addresIds.push(id)
        })
      }

      if (addresIds.length > 0) {
        try {
          const addresses = await Promise.all(addresIds.map(id => this.getAddressGARById(id)))
          parsed.forEach((item, index) => {
            if (Array.isArray(item[addresAttr])) {
              item[addresAttr][0] = addresses[index]
            } else {
              item[addresAttr] = addresses[index]
            }
          })
        } catch (error) {
          console.error(`Error fetching GAR addresses: ${error.message}`)
        }
      }
    },
    getNodeLabel (node) {
      let name = (node.name || node.id)
      if (!this.alias) {
        return name
      }
      let label = this.alias
      label = label.replace(`{{name}}`, name)
      let attributes = this.alias.match(/\{{(.*?)\}}/g) || []
      attributes.forEach((attribute) => {
        attribute = attribute.replace('{{', '').replace('}}', '')
        label = label.replace(`{{${attribute}}}`, node[attribute] || '')
      })

      return label
    },
    async loadOptions ({ action, parentNode, callback }) {
      if (action === LOAD_CHILDREN_OPTIONS) {
        parentNode.children = await this.loadData(true, parentNode.id)
        if (parentNode.children.length) {
          parentNode.isDisabled = false
        }
        callback()
      } else if (action === ASYNC_SEARCH) {
        this.searchLoaded = false
        this.searchLoading = true
        this.foundOptions = await this.loadData(true)
        callback(null, this.foundOptions)
        this.searchLoading = false
        this.searchLoaded = true
      }
    },
    onSearch (value) {
      this.search = value
      this.offset = 0
      if (this.saveOptions) {
        this.saveOptions = false
        return
      }
      this.searchLoaded = false
      this.foundOptions = []
    },
    async onMenuOpen () {
      this.searchLoaded = true
      this.offset = 0
      this.foundOptions = []
      this.options = await this.loadData()
      this.searchLoaded = false
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu()
        const magicNumber = 5
        menu.addEventListener('scroll', async () => {
          const hasReachedEnd = menu.scrollHeight - menu.scrollTop <= (menu.clientHeight + magicNumber)
          if (hasReachedEnd && !this.isAllRecordLoaded && !this.search && !this.groupBy && !this.loading) {
            this.offset += 100
            this.options = await this.loadData(true)
          }
        })
      })
    },
    getParameters (parentNode = null, valueId) {
      let answer = []
      if (this.search) {
        let valueByInput = this.$refs.treeselect.$el.querySelector('.vue-treeselect__input').value
        answer.push(`search=${encodeURIComponent(valueByInput)}`)
        answer.push(`limit=50`)
      } else {
        if (valueId) {
          answer.push(`filter[${this.dataFilters.length}]=id,eqx,${valueId}`)
        } else {
          if (this.groupBy) {
            answer.push(`group_by=${this.groupBy}`)
            if (this.groupOrderBy) {
              answer.push(`order_by=${this.groupOrderBy}`)
            }
            if (parentNode) {
              answer.push(`node=${parentNode}`)
            }
            if (this.recursiveGroup) {
              answer.push(`recursive=1`)
            }
          } else {
            answer.push(`offset=${this.offset}`)
            answer.push(`limit=100`)
          }
        }
      }
      if (this.stateId) {
        answer.push(`state_id=${this.stateId}`)
      }
      this.dataFilters.forEach((filter, index) => {
        answer.push(`filter[${index}]=${filter}`)
      })
      this.stateParams.forEach((param) => {
        answer.push(`parameters[${param.name}]=${param.value}`)
      })
      if (this.applyRestrictions) {
        answer.push(`apply_restrictions=1`)
      }
      return answer
    },
    async loadData (force = false, parentNode = null, valueId = null) {
      if (!this.loaded || force) {
        this.loading = true
        if (this.attributeId) {
          if (this.saveCardNow) {
            this.loading = false
            return this.options
          }
          let response = await this.$http.get(`${this.$config.api}/registryservice/xref/${this.attributeId}/data?${this.getParameters(parentNode, valueId).join('&')}`)
            .catch(() => {
              this.loading = false
            })
          this.loading = false
          this.loaded = true
          if (this.offset > 0) {
            this.isAllRecordLoaded = response.data.length === 0
            return [...this.options, ...response.data]
          } else {
            if (this.groupBy) {
              return (response.data || []).map((item) => {
                item.children = (item.children_count > 0 || (!this.recursiveGroup && !parentNode && !this.search)) ? null : false
                if (item.children === null) {
                  item.isDisabled = true
                }
                return item
              })
            } else {
              return response.data
            }
          }
        }
      }
      return this.options
    },
    onSelect () {
      // Очистить вводимое значение, сохранив результат поиска
      this.saveOptions = true
      this.$refs.treeselect.resetSearchQuery()
    }
  }
}
</script>

<style>
/* Отступ из-за vue-treeselect @TODO vue-treeselect убрать побочный эффект влияния на положение компонента в контейнере */
.xref_multi_field_label {
    position: relative;
    top: 13px;
}
/* Отступ из-за vue-treeselect @TODO vue-treeselect убрать побочный эффект влияния на положение компонента в контейнере */
.xref_multi_field.vue-treeselect {
    display: inline-block;
    top: 16px;
}
.xref_multi_field .vue-treeselect__control {
    height: 40px;
}

.xref_multi_field .vue-treeselect__single-value {
    color: #606266;
}

.xref_multi_field .vue-treeselect__input {
    color: #606266;
    vertical-align: baseline;
}

.xref_multi_field .vue-treeselect__placeholder {
    line-height: 40px;
}

.xref_multi_field .vue-treeselect__value-container {
    line-height: 20px;
}
.vue-treeselect__portal-target {
    z-index: 10000 !important;
}
.el-form-item.is-error .xref_multi_field {
  border: 1px solid red;
  border-radius: 6px;
}
.xref_multi_field.vue-treeselect--disabled .vue-treeselect__control {
    background-color: #F5F7FA;
    border-color: #E4E7ED;
    cursor: not-allowed;
}
.xref_multi_field.vue-treeselect--disabled .vue-treeselect__single-value {
    color: #C0C4CC;
}
</style>
